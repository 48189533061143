<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${isAdd ? $t('actions.add') : $t('actions.edit')} ${ $tc('iotcomms_master_account.title', 1)}`}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('region.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- code -->
          <validation-provider
            #default="validationContext"
            name="code"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('region.code')"
              label-for="name"
            >
              <b-form-input
                id="code"
                v-model="itemData.code"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- acronym -->
          <validation-provider
            #default="validationContext"
            name="acronym"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('region.acronym')"
              label-for="acronym"
            >
              <b-form-input
                id="acronym"
                v-model="itemData.acronym"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- country -->
          <validation-provider
            #default="validationContext"
            name="country"
          >
            <b-form-group
              :label="$tc('country.title', 1)"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.country_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="(val) => val.value"
                input-id="sector"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t('actions.add') : $t('actions.edit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t('actions.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, positive } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    }
  },
  setup(props, { emit }) {
    const blankData = {
      name: '',
      code: '',
      acronym: '',
      country_id: null,
    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      let req = itemData.value

      let dispatch

      if (props.isAdd) {
        dispatch = store.dispatch('region/add', req)
      } else {
        const data = {
          id: itemData.value.id,
          params: req,
        }

        dispatch = store.dispatch('region/edit', data)
      }

      dispatch
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-sidebar-active', false)
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetData)

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit
        itemData.value.country_id = props.itemEdit.id_country
      }
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    }
  },
}
</script>