import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('country/getAll')

  const countryOptions = computed(() => store.getters['country/getSelectedItems'])
  const countryFilter = ref(null)

  const tableColumns = [
    { label: 'region.id', key: 'id', sortable: true },
    { label: 'region.name', key: 'name', sortable: true },
    { label: 'region.code', key: 'code', sortable: true },
    { label: 'region.acronym', key: 'acronym', sortable: true },
    { label: 'country.title', key: 'country.name', sortable: true },
  ]

  if (ability.can('update', 'Region') === true || ability.can('delete', 'Region') === true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const action = ref('list')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('region/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      action: action.value,
      id_country: countryFilter.value,
    })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
        } else {
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([
    countryFilter,
    currentPage,
    perPage,
  ], () => { refetchData() })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    refetchData,

    countryOptions,

    countryFilter,

    action,
  }
}
